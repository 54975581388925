import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
import './ConfirmationCode.css'; // Importer le fichier CSS
import { useDispatch } from 'react-redux';
import { confirmSms } from '../../../Redux/Actions/adherantAction';
import { useNavigate } from 'react-router';

const ConfirmationCode = (props) => {
  const correctCode = props.code; // Code correct
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [validated, setValidated] = useState(false); // Nouvel état pour suivre la validation
  const [newADH, setNewADH] = useState(
    props.type === "شعير"
      ? { codeOrge: "", obtenuOrge: true, reserveProxyO: '' }
      : { codeCedra: "", obtenuCedra: true, reserveProxyC: '' }
  );

  // Date d'aujourd'hui
  const aujourdHui = new Date();
 
  // Date fournie
  const dateFournie = new Date(props.date);

  // Période en jours
  const periode = props.period;

  // Calcul de la date de validation
  const dateValidation = new Date(props.date);
  dateValidation.setDate(parseInt(dateValidation.getDate()) + parseInt(periode));

  // Vérification de la date
  const validationdate = dateValidation >= aujourdHui;

  useEffect(() => {
    if (confirmationStatus === "Correct") {
      dispatch(confirmSms(props.el._id, newADH));
      
    }
  }, [confirmationStatus]);

  const handleConfirmWithoutInput = () => {
    const searchTerm = props.searchTerm;
    
    if (!searchTerm) {
      setAlertMessage("الرجاء إدخال الرمز السري في خانة البحث !! ");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000); // Masquer l'alerte après 3 secondes
      return;
    }

    if (searchTerm === correctCode) {
      if (validationdate) {
        setConfirmationStatus('Correct');
        setWrongCode(false);
        setAlertMessage("");
        setShowAlert(false);
        setValidated(true); // Mettre à jour l'état de validation
        setTimeout(()=>{navigate("/ConfirmationSmsUn")},3000)
      } else {
        
        // Calculer le nombre de jours dépassés
        const daysExceeded = Math.ceil((aujourdHui - dateValidation) / (1000 * 60 * 60 * 24));
        setAlertMessage(`نرجو المعذرة، لقد تجاوزت مدة استلامك بـ ${daysExceeded} أيام.`);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000); // Masquer l'alerte après 3 secondes
      }
    } else {
      setWrongCode(true);
      setAlertMessage("الرجاء التأكد من الرمز السري !!");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000); // Masquer l'alerte après 3 secondes
    }
  };

  return (
    <div className="confirmation-code-container">
      {showAlert && (
        <div className="alert-container">
          <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
            {alertMessage}
          </Alert>
        </div>
      )}
      <Button onClick={handleConfirmWithoutInput} style={{ marginTop: '10px' }}>
        تأكيد
      </Button>
      {wrongCode && (
        <p style={{ color: 'red' }}>الرمز خاطئ</p>
      )}
      {validated && (
        <Button variant="danger" disabled>
          تم الإستلام
        </Button>
      )}
    </div>
  );
};

export default ConfirmationCode;
