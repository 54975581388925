import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { toast } from "react-toastify";
import { getADHApproSupp,  resetAdherantOrge, resetConfirmListAdh } from "../../../Redux/Actions/adherantAction";
import gifSendingSms from "../../../Images/gifSendingSms.gif"
const SuppSmsOrge = () => {
const ADHList=useSelector(state=>state.adherantReducer.StatisticADH)

  // Modal logic
  const [show, setShow] = useState(false);
  const [reset,setReset]=useState(false)
  const approlist = useSelector((state) => state.userReducer.approlist);
  const adhList = useSelector((state) => state.adherantReducer.adhListorge);
  const user = useSelector((state) => state.userReducer.user)
const [loading,setLoading]=useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const [proxy, setProxy] = useState(null);
  const [min,setMin]=useState(0)
  const [max,setMax]=useState(0)
  const [validated, setValidated] = useState(false);
  const [pourcentage, setPourcentage] = useState(1);
  const [qt, setQt] = useState(0);
  const [period,setPeriod]=useState(15)
  const [camionNumber,setCamionNumber]=useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [rest, setRest] = useState(0);
  const [qtCamion, setQtCamion] = useState(0);
  const [restDistribution,setRestDistribution]=useState(0)
  const [newadhList, setNewadhList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [listAdh,setListAdh]=useState()
  const [reserveProxy,setReserveProxy]=useState('')
  const date=new Date()
  useEffect(()=>{
    return () => {
   
      dispatch(resetConfirmListAdh()); // Réinitialise la liste à un tableau vide
  };
  },[])
  useEffect(()=>{
    setRestDistribution(approlist?approlist.filter(el=>el.name==proxy)[0]?.restDistributionOrge:0)
  },[proxy])
  useEffect(()=>{
    setRest(qt)
    setSelectedRows([])
  },[qt,pourcentage])
  // select logic

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows([...newadhList]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (row) => {
    const selectedIndex = selectedRows.findIndex((el) => el._id === row._id);
    let newSelectedRows = [...selectedRows];
    
    // Calcul de cotaO
    let cotaO = Math.round(
      ((((row.chevre + row.mouton) * 8.25 +
        row.vache * 60 +
        (row.cheval + row.chameau) * 21)) /
        50) *
        pourcentage
    ) !== 0
      ? Math.round(
          ((((row.chevre + row.mouton) * 8.25 +
            row.vache * 60 +
            (row.cheval + row.chameau) * 21)) /
            50) *
            pourcentage
        )
      : 1;
  
    if (selectedIndex === -1) {
      // Cas de sélection d'une nouvelle ligne
      if (rest < cotaO) {
        alert(`لا يمكن اضافة الفلاح,الكمية المتبقية ${rest} اكياس اقل من نصيب الفلاح`);
            } else {
        setRest(rest - cotaO);  // Décrémenter rest lors de la sélection
        newSelectedRows.push(row);
      }
    } else {
      // Cas de désélection d'une ligne
      setRest(rest + cotaO);  // Réincrémenter rest lors de la désélection
      newSelectedRows.splice(selectedIndex, 1);
    }
  
    setSelectedRows(newSelectedRows);
  };
 // Mettre à jour qt et qtCamion ensemble
 const handleQtCamionChange = (e) => {
  const value = e.target.value;
  setQtCamion(value);
  setQt(value); // Mettre à jour qt avec la même valeur
};
const handleButtonClick = () => {
  toast(
    <div>
      <p>سوف يتم التخلص من الكمية المتخلدة بالذمة</p>
      <Button
        onClick={() => {
          resetRest();
          toast.dismiss(); // Close the toast after confirming
        }}
        variant="success"
      >
        موافقة
      </Button>
      <Button
        onClick={() => toast.dismiss()} // Close the toast without action
        variant="danger"
      >
        إلغاء
      </Button>
    </div>,
    {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false, // Keep the toast open until the user interacts
      closeOnClick: false, // Prevent closing by clicking outside
      draggable: false // Prevent dragging to close
    }
  );
};
  // calculation logic

  // const calculListAppro = () => {
  //   let tempNewadhList = [];
  //   let remainingQt = qt;

  //   for (let i = 0; i < adhList.length; i++) {
  //     const cota = Math.round(
  //       (((adhList[i].chevre + adhList[i].mouton) * 8.25 +
  //         adhList[i].vache * 60 +
  //         (adhList[i].cheval + adhList[i].chameau) * 21) /
  //         50) *
  //         pourcentage
  //     )>0?Math.round(
  //       (((adhList[i].chevre + adhList[i].mouton) * 8.25 +
  //         adhList[i].vache * 60 +
  //         (adhList[i].cheval + adhList[i].chameau) * 21) /
  //         50) *
  //         pourcentage
  //     ):1;

  //     if(min>0 && max>0){
  //       if (cota < remainingQt && cota>min-1 && cota<parseInt(max)+1) {
  //         tempNewadhList.push(adhList[i]);
  //         remainingQt -= cota;
  //       }
  //     }else{
  //       if(min>0 && max==0){
  //         if (cota < remainingQt && cota>min-1 ) {
  //           tempNewadhList.push(adhList[i]);
  //           remainingQt -= cota;
  //         }
  //       }else{
  //         if(max>0 && min==0){
  //           if (cota < remainingQt && cota<parseInt(max)+1 ) {
  //             tempNewadhList.push(adhList[i]);
  //             remainingQt -= cota;
  //           }
  //         }else{
  //           if (cota < remainingQt) {
  //             tempNewadhList.push(adhList[i]);
  //             remainingQt -= cota;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   setNewadhList(tempNewadhList);
  //   setRest(remainingQt);
  // };

  // Your table data
  const tableData = [
    // ... Your table data
  ];
  const buttonStyle = {
    marginLeft: "10px", // Adjust the spacing as needed
    borderRadius: "50%", // Make the button circular
    width: "40px", // Adjust the width as needed
    height: "40px", // Adjust the height as needed
    padding: "0", // Remove default padding
    textAlign: "center", // Center text horizontally
    lineHeight: "40px", // Center text vertically
  };
  // Filter table rows based on the search term
  const filteredRows = tableData.filter((row) => row[1].includes(searchTerm));

  const getAdh = async () => {
    if (proxy != null) {
      dispatch(getADHApproSupp({ proxy: proxy }));
    }
  };
  const calculCota=(el)=>{
    const cotaO=Math.round(
      (((el.chevre + el.mouton) * 8.25 +
        el.vache * 60 +
        (el.cheval + el.chameau) * 21) /
        50) *
        pourcentage
    )
    if(cotaO==0){
      return 1
    }else{
      return cotaO
    }
  }
  const sendSms = async () => {
    setLoading(true)
    const CHUNK_SIZE = 20; // Ajustez cette taille selon vos besoins
    let arrayData=[];
    for (const el of selectedRows) {
      const cota = JSON.stringify(calculCota(el));
      const smsData = {
        el: el,
        cota: cota,
        type: "شعير",
        code: generateCode(),
        date: date,
        period: period,
        reserveProxyO:reserveProxy
      };
      arrayData.push(smsData)
    }
  
      // Fonction pour envoyer un chunk de données
    const sendChunk = async (chunk) => {
      try {
          const response = await axios.post("/api/send-sms", chunk);
          const { messageIds } = response.data;

          if (messageIds && messageIds.length > 0) {
              await axios.post("/api/user/SaveMsgID", { messageIds, dependency: chunk[0].dependency, User: user.name });
          }
          
      } catch (error) {
          console.error("Erreur lors de l'envoi du SMS :", error);
      }
  };
    // Diviser arrayData en chunks et envoyer chaque chunk
    const sendChunks = async () => {
      for (let i = 0; i < arrayData.length; i += CHUNK_SIZE) {
          const chunk = arrayData.slice(i, i + CHUNK_SIZE);
          await sendChunk(chunk);
      }
      
  };

  await sendChunks();
    // il faut pas l'afficher dans historique niyabat
    await axios.post("/api/user/updateHistory",{proxy:reserveProxy?reserveProxy:proxy,camionNumber:camionNumber,type:"شعير",qt:qtCamion,pourcentage:pourcentage,date:date,supp:true,restCamion:rest})
    if(camionNumber){
      await fetch("/api/user/updateCamionO", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ proxy: proxy })
    });
    }
    setLoading(false)
  };
  const resetRest=async()=>{
    // Assurez-vous que `qt` est un nombre
const numericQt = Number(qtCamion);

// Assurez-vous que `restDistributionOrge` est un nombre
const restDistributionOrge = Number(approlist?.filter(el => el.name === proxy)[0]?.restDistributionOrge) || 0;

// Effectuez l'addition
setQt(numericQt + restDistributionOrge);
setRestDistribution(0)
    await axios.post("/api/user/resetDistribution",{proxy:proxy,type: "شعير"})
    
  }
// Fonction utilitaire pour créer un délai avec une promesse


  // useEffect(() => {
  //   if (adhList) {
  //     calculListAppro();
  //   }
  // }, [adhList]);
  // Code generation logic

  const generateCode = () => {
    const possibleChars =
      "abcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";

    for (let i = 0; i < 5; i++) {
      code += possibleChars.charAt(
        Math.floor(Math.random() * possibleChars.length)
      );
    }

    return code;
  };
  const handleReset=()=>{
    dispatch(resetAdherantOrge({ proxy: proxy }))
    setReset(false)
  }
   // Search Logic
   const handleSearch = () => {
    if (searchTerm) {
      // Filtrer les éléments basés sur le `searchTerm`
      const filteredList = adhList.filter((e) => e?.cin==searchTerm);
      setListAdh(filteredList);
    } else {
      setListAdh(adhList);
    }
  };

  return (
    <div>
{loading?<img style={{width:"1000px",height:"650px"}} src={gifSendingSms}/>:
<div>
      <Form
        noValidate
        validated={validated}
        className="p-4  rounded  text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1>فضاء الرسائل التكميلية للشعير</h1>
        {/* label  1 */}
        <Form.Group style={{ marginTop: "70px" }} as={Row} className="mb-3">
          <Form.Label column md={2} className="text-end order-md-2">
            تاجر الأعلاف
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Select
              className="text-end"
              placeholder="ــ اختيار ــ"
              onChange={(e) => setProxy(e.target.value)}
            >
              <option>ــ اختيار ــ </option>
              {approlist?.map((el) => (
                <option key={el._id} value={el.name}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        {/* label kmeyt el che7na */}
        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
          <Form.Label column md={2} className="text-end order-md-2">
          كمية الشاحنة بالكيس
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              name="mouton"
              type="tel"
              placeholder=" 0 "
              defaultValue={qtCamion}
              value={qtCamion}
              className="text-end"
              onChange={handleQtCamionChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
            <Form.Label column md={2} className="text-end order-md-2">
              الكمية المتخلدة بالذمة
            </Form.Label>
            <Col md={{ span: 3, offset: 6 }} className="order-md-1 d-flex align-items-center">
              <Form.Control
                name="reste Quantity"
                type="tel"
                placeholder="0"
                className="text-end"
                value={restDistribution}
                disabled
              />
              <Button
                          onClick={handleButtonClick}
                          style={buttonStyle} // Apply the imported style
                          className="btn-primary"
              >
                +
              </Button>
            </Col>
          </Form.Group>
          {/* reste Quantity */}
        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
          <Form.Label column md={2} className="text-end order-md-2">
            الكمية المتبقية
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Control
              name="reste Quantity"
              type="tel"
              placeholder=" 0 "
              className="text-end"
              value={rest}
            />
          </Col>
        </Form.Group>
        {/* percentage */}

        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
          <Form.Label column md={2} className="text-end order-md-2">
            النسبة المئوية
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Select
              name="percentage"
              className="text-end"
              defaultValue="1"
              onChange={(e) => setPourcentage(e.target.value)}
            >
              
              <option value="0.3">30%</option>
              <option value="0.35">35%</option>
              <option value="0.4">40%</option>
              <option value="0.45">45%</option>
              <option value="0.5">50%</option>
              <option value="0.55">55%</option>
              <option value="0.6">60%</option>
              <option value="0.65">65%</option>
              <option value="0.7">70%</option>
              <option value="0.75">75%</option>
              <option value="0.8">80%</option>
              <option value="0.85">85%</option>
              <option value="0.9">90%</option>
              <option value="0.95">95%</option>
              <option value="1">100%</option>
              <option value="1.1">110%</option>
              <option value="1.15">115%</option>
              <option value="1.2">120%</option>
              <option value="1.25">125%</option>
              <option value="1.3">130%</option>
              <option value="1.35">135%</option>
              <option value="1.4">140%</option>
              <option value="1.45">145%</option>
              <option value="1.5">150%</option>
              <option value="1.55">155%</option>
              <option value="1.6">160%</option>
              <option value="1.65">165%</option>
              <option value="1.7">170%</option>
              <option value="1.75">175%</option>
              <option value="1.8">180%</option>
              <option value="1.85">185%</option>
              <option value="1.9">190%</option>
              <option value="1.95">195%</option>
              <option value="2">200%</option>
              <option value="2.5">250%</option>
              <option value="3">300%</option>
              
            </Form.Select>
          </Col>
        </Form.Group>

        

        {/* Quantity Range */}
        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
          <Form.Label column md={2} className="text-end order-md-2">
            مجال الكمية
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Row>
              {/* First Form Label and Control */}
              <Col md={6}>
                <Form.Label className="text-end">نهاية</Form.Label>
                <Form.Control
                  name="startQuantity"
                  type="tel"
                  onChange={(e)=>setMax(e.target.value)}
                  placeholder="0"
                  className="text-end"
                />
              </Col>
              {/* Second Form Label and Control */}
              <Col md={6}>
                <Form.Label className="text-end">بداية</Form.Label>
                <Form.Control
                  name="endQuantity"
                  type="tel"
                  placeholder="0"
                  onChange={(e)=>setMin(e.target.value)}
                  className="text-end"
                />
              </Col>
            </Row>
          </Col>
        </Form.Group>

        {/* receipt date */}

        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
          <Form.Label column md={2} className="text-end order-md-2">
             مدة الاستلام 
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
  <Form.Select
    name="Duration of receipt"
    className="text-end"
    defaultValue={period}
    onChange={(e)=>setPeriod(e.target.value)}
  >
    {[...Array(14)].map((_, index) => (
      <option key={index + 1} value={index + 1}>
        {index + 1}
      </option>
    ))}
    <option value="15">15</option>
  </Form.Select>
</Col>

        </Form.Group>

        {/* Truck Number */}

        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
          <Form.Label column md={2} className="text-end order-md-2">
             رقم الشاحنة  
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
  <Form.Select
    name="Duration of receipt"
    className="text-end"
    defaultValue="0"
    onChange={(e)=>setCamionNumber(e.target.value)}
  >
    <option value="0">0</option>
    {[...Array(9)].map((_, index) => (
      <option key={index + 1} value={index + 1}>
        {index + 1}
      </option>
    ))}
    <option value="10">10</option>
  </Form.Select>
</Col>


        </Form.Group>


        {/* nieba Mouawedha */}
        <Form.Group as={Row} className="mb-3" style={{ marginTop: "30px" }}>
        <Form.Label column md={2} className="text-end order-md-2">
           النيابة المعوضة
        </Form.Label>
        <Col md={{ span: 3, offset: 6 }} className="order-md-1">
          <Form.Select
            name="Duration of receipt"
            className="text-end"
            
            onChange={(e)=>setReserveProxy(e.target.value)}
          >
            <option>ــ اختيار المعوض ــ </option>
              {approlist?.map((el) => ( el.name!=proxy &&
                <option key={el._id} value={el.name}>
                  {el.name}
                </option>))}
          </Form.Select>
        </Col>
      </Form.Group>

        <Button
          onClick={getAdh}
          variant="primary"
          style={{ marginTop: "50px" }}
        >
          تأكيد المعلومات
        </Button>

        {/* table   */}
        <div style={{ height: "400px", overflowY: "auto", marginTop: "100px" }}>
        <Table
          striped
          bordered
          hover
          variant="dark"
          responsive="md"
          dir="rtl"
          style={{ marginTop: "10px" }}
        >
          <thead>
          <tr>
          <th colSpan="10">
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="ابحث..."
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  marginBottom: "1px",
                  width: "100%",
                  padding: "5px",
                  color: "black",
                }}
              />

              <Button
                onClick={handleSearch}
                style={{ marginRight: "10px" }}
              >
                ابحث
              </Button>
            </div>
          </th>
        </tr>
            <tr>
              <th></th>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              
              <th>رقم ب.ت.و</th>
              <th>الاسم</th>
              <th>اللقب</th>
              <th>الهاتف </th>
              <th>كمية الشعير بالكيس </th>
              <th>النسبة المئوية </th>
              <th>مدة الاستلام </th>
            </tr>
          </thead>
          <tbody>
            {adhList?.map((el, index) => (
              <tr key={el._id}>
                <td  style={{ width: '50px' }} >{index + 1}</td>
                <td style={{ width: '50px' }}>
                  <input
                    type="checkbox"
                    checked={selectedRows.some((row) => row._id === el._id)}
                    onChange={() => handleSelectRow(el)}
                  />
                </td>
                <td>{String(el.cin)}</td>
                <td>{el.name}</td>
                <td>{el.lastname}</td>
                <td>{el.phone}</td>
                <td>
                  {Math.round(
                    ((((el.chevre + el.mouton) * 8.25 +
                      el.vache * 60 +
                      (el.cheval + el.chameau) * 21) 
                      ) /
                      50) *
                      pourcentage
                  ) == 0 ? 1 : Math.round(
                    ((((el.chevre + el.mouton) * 8.25 +
                      el.vache * 60 +
                      (el.cheval + el.chameau) * 21) 
                      ) /
                      50) *
                      pourcentage
                  )}
                </td>
                <td>{pourcentage *100 +"%"}</td>
                  <td>{period}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>


    

        {/* label  3 */}

        {/* label  button modal*/}
        <Button
          variant="primary"
          onClick={handleShow}
          style={{ marginTop: "50px" }}
        >
          ارسال الرسائل
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>تأكيد الأرسال</Modal.Title>
          </Modal.Header>
          <Modal.Body dir="rtl">يرجى التأكد من كمية الشاحنة تساوي {qt} </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              اغلاق
            </Button>
            <Button variant="primary" onClick={ () => (sendSms() , handleClose(), toast("تم ارسال الارساليات بنجاح"))}>
              تأكيد الأرسال
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  Modal  + rest button  */}
        {/* label  button modal*/}
        <Button
          variant="danger"
          onClick={()=>setReset(true)}
          style={{ marginTop: "50px" }}
        >
        إعادة تعيين الفلاحة
        </Button>

        <Modal
  show={reset}
  onHide={()=>setReset(false)}
  backdrop="static"
  keyboard={false}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered
>
  <Modal.Header closeButton style={{ backgroundColor: '#ffcccc', color: 'red' }}>
    <Modal.Title>تحذير: إعادة تعيين الفلاحة</Modal.Title>
  </Modal.Header>
  <Modal.Body dir="rtl" style={{ color: 'red' }}>
    <p>
      تحذير: بالنقر على زر التأكيد، تقر بأن جميع الفلاحين سيتم إزالتهم من قائمة تأكيد الرسائل . مما يشير إلى أن جميعهم قد استلموا رسائلهم النصية. ونتيجة لذلك، لن يكون لديك أي فلاحين آخرين متبقين لإرسال رسائل نصية لهم. <br/>المسؤولية عن هذا الإجراء تقع بالكامل على الشخص الذي  يقوم بالتأكيد .
    </p>
  </Modal.Body>
  <Modal.Footer style={{ backgroundColor: '#ffcccc', color: 'red' }}>
    <Button variant="secondary" onClick={()=>setReset(false)}>
      اغلاق
    </Button>
    <Button variant="primary" onClick={() => handleReset()}>
      إعادة تعيين الفلاحة
    </Button>
  </Modal.Footer>
</Modal>

      </Form>
      {/* CommingSoon */}
    </div>
  }</div>
    
  );
};

export default SuppSmsOrge;
