import { toast } from "react-toastify"

const { ADD_ADHERANT, LOAD_ADHERANT, FAIL_ADHERANT, EDIT_ADHERANT, FIND_ADHERANT, DELETE_ADHERANT, CLEAR_ADHERANT, GET_ADHERANTORGE, GET_ADHERANTCEDRA, GET_ADHERANTSMSORGE, GET_ADHERANTSMSCEDRA, GET_STATISTICADH, CONFIRM_SMS, RESET_ADHERANT_ORGE, RESET_ADHERANT_CEDRA, CONFIRMED_SMS, GET_ADH_SUPP, RESET_CONFIRM_LIST } = require("../ActionType/actionTypes")



const initialState ={
    adherant:null,
    loadADH:false,
    errors:null,
    adhListorge:null,
    adhListcedra:null,
    adhlistSMSorge:null,
    adhlistSMScedra:null,
    StatisticADH:null,
    confirmedList:null
}



const adherantReducer=(state=initialState,{type,payload})=>{
    
    switch (type) {
        case ADD_ADHERANT:
            toast(payload.msg)
            return{...state,loadADH:false}
        case LOAD_ADHERANT:
            return{...state,loadADH:true}
        case EDIT_ADHERANT:
            toast(payload.msg)
            return{...state,loadADH:false,adherant:null}
        case FAIL_ADHERANT:
            toast(payload.data.msg)
            return{...state,loadADH:false,errors:payload}
        case FIND_ADHERANT:
                return{...state,adherant:payload.foundADH,loadADH:false}
                
        case DELETE_ADHERANT:
            toast(payload.msg)
            return{...state,adeherant:null,loadADH:false}
        case CLEAR_ADHERANT:
            return{...state,adherant:null}
        case GET_ADHERANTORGE:
            return{...state,adhListorge:payload.filerADH,loadADH:false}
        case GET_ADHERANTCEDRA:
                return{...state,adhListcedra:payload.filterADH,loadADH:false}
        case GET_ADHERANTSMSORGE:
                return{...state,adhlistSMSorge:payload.filerADH,loadADH:false}
        case GET_ADHERANTSMSCEDRA:
                return{...state,adhlistSMScedra:payload.filterADH,loadADH:false}
        case GET_STATISTICADH:
            return{...state,StatisticADH:payload.foundADH,loadADH:false}
        case CONFIRM_SMS:
            toast(payload.msg)
            return state
        case RESET_ADHERANT_ORGE:
            return state
        case RESET_ADHERANT_CEDRA:
            return state
        case CONFIRMED_SMS:
            return{...state,confirmedList:payload.filterADH,loadADH:false}
        case GET_ADH_SUPP:
            return{...state,adhListorge:payload.foundADH,adhListcedra:payload.foundADH,loadADH:false}
        case RESET_CONFIRM_LIST:
            return{...state,adhlistSMSorge:null,adhlistSMScedra:null,adhListcedra:null,adhListorge:null,confirmedList:null}
        default:
            return state
            
    }
}

export default adherantReducer