

//user actions
export const LOGIN_USER="LOGIN_USER"
export const REGISTER_USER="REGISTER_USER"
export const LOGOUT_USER="LOGOUT_USER"
export const LOAD_USER="LOAD_USER"
export const FAIL_USER="FAIL_USER"
export const CURRENT_USER="CURRENT_USER"
export const FIND_APPRO="FIND_APPRO"
export const EDIT_APPRO="EDIT_APPRO"
export const DELETE_APPRO="DELETE_APPRO"
export const CLEAR_APPRO= "CLEAR_APPRO"
export const GET_APPROS="GET_APPROS"
export const GET_APPROSTATISTIC="GET_APPROSTATISTIC"
export const GET_UNIONSTATISTIC="GET_UNIONSTATISTIC"

//adherant actions

export const ADD_ADHERANT="ADD_ADHERANT"
export const LOAD_ADHERANT="LOAD_ADHERANT"
export const FAIL_ADHERANT="FAIL_ADHERANT"
export const EDIT_ADHERANT="EDIT_ADHERANT"
export const FIND_ADHERANT="FIND_ADHERANT"
export const DELETE_ADHERANT="DELETE_ADHERANT"
export const CLEAR_ADHERANT= "CLEAR_ADHERANT"
export const GET_ADHERANTORGE="GET_ADHERANTORGE"
export const GET_ADHERANTCEDRA="GET_ADHERANTCEDRA"
export const GET_ADHERANTSMSCEDRA="GET_ADHERANTSMSCEDRA"
export const GET_ADHERANTSMSORGE="GET_ADHERANTSMSORGE"
export const GET_STATISTICADH="GET_STATISTICADH"
export const CONFIRM_SMS="CONFIRM_SMS"
export const RESET_ADHERANT_ORGE="RESET_ADHERANT_ORGE"
export const RESET_ADHERANT_CEDRA="RESET_ADHERANT_CEDRA"
export const CONFIRMED_SMS="CONFIRMED_SMS"
export const GET_ADH_SUPP="GET_ADH_SUPP"
export const RESET_CONFIRM_LIST="RESET_CONFIRM_LIST"