import { toast } from "react-toastify"
import { CLEAR_APPRO, CURRENT_USER, DELETE_APPRO, EDIT_APPRO, FAIL_USER, FIND_APPRO, GET_APPROS, GET_APPROSTATISTIC, GET_UNIONSTATISTIC, LOAD_USER, LOGIN_USER, LOGOUT_USER, REGISTER_USER } from "../ActionType/actionTypes"
import axios from "axios"


// register user 
export const register =(newUser)=>async(dispatch) =>{
    dispatch({type:LOAD_USER})
    try {
        let result = await axios.post('/api/user/register', newUser)
        dispatch({type:REGISTER_USER, payload : result.data})
    } catch (error) {
        dispatch({type: FAIL_USER, payload: error.response})
    }
    }
    //Login user 
    
    export const login =(user)=>async(dispatch) =>{
        dispatch({type:LOAD_USER})
        try {
            let result = await axios.post('/api/user/Login', user)
            dispatch({type:LOGIN_USER, payload : result.data})
        } catch (error) {
            dispatch({type: FAIL_USER, payload: error.response})
        }
        }
    
        // logout user
    export const logout = () => async (dispatch) =>{
        dispatch({type:LOGOUT_USER})
    }

//current user
    export const current = ()=>async (dispatch) =>{
        dispatch({type:LOAD_USER})
        try {
            const config = {
                headers: {authorization: localStorage.getItem('token')}
            }
            let result = await axios.get('/api/user/current', config)
            dispatch({type:CURRENT_USER, payload:result.data})
        } catch (error) {
            dispatch({type:FAIL_USER, payload:error.response})
        }
    }

    export const findappro=(email)=>async(dispatch)=>{
        dispatch({type:LOAD_USER})
        try {
            let result=await axios.post('/api/user/findappro',email)
            dispatch({type:FIND_APPRO,payload:result.data})
        } catch (error) {
            dispatch({type:FAIL_USER,payload:error.response})
        }
    }

    export const editAppro=(_id,Appro)=>async(dispatch)=>{
        dispatch({type:LOAD_USER})
        try {
            if(Appro){
            let result=await axios.put(`/api/user/editappro/${_id}`,Appro)
            dispatch({type:EDIT_APPRO,payload:result.data})}
            else{
                toast("يرجى ادخال المعلومات")
            }
        } catch (error) {
            dispatch({type:FAIL_USER,payload:error.response})
        }
    }

    export const deleteAppro=(_id)=>async(dispatch)=>{
        dispatch({type:LOAD_USER})
        try {
            
            let result=await axios.delete(`/api/user/deleteappro/${_id}`)
            dispatch({type:DELETE_APPRO,payload:result.data})
            
        } catch (error) {
            dispatch({type:FAIL_USER,payload:error.response})
        }
    }
    export const clearAppro =()=>async(dispatch)=>{
        dispatch({type:CLEAR_APPRO})
      };

    export const getappros=(dependency)=>async(dispatch)=>{
        dispatch({type:LOAD_USER})
        try {
            let result=await axios.post('/api/user/getAppros',dependency)
            dispatch({type:GET_APPROS,payload:result.data})
        } catch (error) {
            dispatch({type:FAIL_USER,payload:error.response})
        }
    }

    export const getStatisticappros=(dependency)=>async(dispatch)=>{
        dispatch({type:LOAD_USER})
        try {
            let result=await axios.post('/api/user/getStatisticAppro',dependency)
            dispatch({type:GET_APPROSTATISTIC,payload:result.data})
        } catch (error) {
            dispatch({type:FAIL_USER,payload:error.response})
        }
    }

    export const getStatisticUnion=(dependency)=>async(dispatch)=>{
        dispatch({type:LOAD_USER})
        try {
            let result=await axios.post('/api/user/getStatisticUnion',dependency)
            dispatch({type:GET_UNIONSTATISTIC,payload:result.data})
        } catch (error) {
            dispatch({type:FAIL_USER,payload:error.response})
        }
    }

   